import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import './style.css';

const TextBoxEditor = (props) => {
    const {
        checkCharacterCount,
        id,
        text,
        setText,
        isEditing,
        editorRef
    } = props;

    useEffect(() => {
        const quillInstance = editorRef.current.getEditor();
        const editorContainer = quillInstance.root;

        const stopEventPropagation = (event) => {
            event.stopPropagation();
        };

        const handleDoubleClick = (event) => {
            event.stopPropagation();
            quillInstance.focus();
            quillInstance.setSelection(0, quillInstance.getLength());
        };

        const handleArrowKeys = (event) => {
            if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                event.stopPropagation();
            }
        };

        if (isEditing) {
            editorContainer.addEventListener('mousedown', stopEventPropagation);
            editorContainer.addEventListener('touchstart', stopEventPropagation);
            editorContainer.addEventListener('dblclick', handleDoubleClick);
            editorContainer.addEventListener('keydown', handleArrowKeys);
        } else {
            editorContainer.removeEventListener('mousedown', stopEventPropagation);
            editorContainer.removeEventListener('touchstart', stopEventPropagation);
            editorContainer.removeEventListener('dblclick', handleDoubleClick);
            editorContainer.removeEventListener('keydown', handleArrowKeys);
        }

        return () => {
            editorContainer.removeEventListener('mousedown', stopEventPropagation);
            editorContainer.removeEventListener('touchstart', stopEventPropagation);
            editorContainer.removeEventListener('dblclick', handleDoubleClick);
            editorContainer.removeEventListener('keydown', handleArrowKeys);
        };
    }, [isEditing]);

    useEffect(() => {
        const quillInstance = editorRef.current.getEditor();

        const handlePaste = (event) => {
            event.preventDefault();

            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('text/plain') || clipboardData.getData('text') || '';

            const cleanedText = pastedText.replace(/[\r\n]+/g, ' ').trim();

            const range = quillInstance.selection.savedRange;

            if (cleanedText) {
                if (range) {
                    quillInstance.deleteText(range.index, range.length); // Remove current selection
                    quillInstance.insertText(range.index, cleanedText); // Insert cleaned text
                } else {
                    // If no range is selected, insert text at the end
                    const endIndex = quillInstance.getLength(); // Get end of document
                    quillInstance.insertText(endIndex, cleanedText); // Append cleaned text at the end
                }
                setTimeout(() => quillInstance.setSelection(range.index + cleanedText.length, 0), 100)
            }
        };

        const editorContainer = quillInstance.root;
        editorContainer.addEventListener('paste', handlePaste);

        return () => {
            editorContainer.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <ReactQuill
            onKeyDown={checkCharacterCount}
            className={`quill-text-tool-${id} no-select`}
            ref={editorRef}
            theme='snow'
            value={text}
            onChange={setText}
            modules={{
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike']
                ],
                clipboard: {
                    matchVisual: false // Prevent Quill from auto-adding visual formatting
                }
            }}
        />
    );
}

export default TextBoxEditor;
